import { useExperimentStore } from "../../data/repositories";
import { EXPERIMENTS } from "../../domain/constants";
import { createExperimentViewModel, ExperimentViewModel } from "../../domain/view-models";

type UseExperimentResult = {
  isInExperiment: boolean;
  variantId: string | null;
  experimentViewModel: ExperimentViewModel | null;
};

export const useExperiment = (experimentId: string): UseExperimentResult => {
  const { assignments } = useExperimentStore();
  const notInExperiment: UseExperimentResult = {
    isInExperiment: false,
    variantId: null,
    experimentViewModel: null,
  };

  try {
    const experiment = EXPERIMENTS.find((exp) => exp.id === experimentId);
    if (!experiment) return notInExperiment;

    const variantId = assignments[experimentId].variantId;
    if (!variantId) return notInExperiment;

    const experimentViewModel = createExperimentViewModel(experiment, variantId);

    return {
      isInExperiment: true,
      variantId,
      experimentViewModel,
    };
  } catch (error) {
    return notInExperiment;
  }
};
