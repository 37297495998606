import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactNode, useMemo } from "react";

import { usePromotionCode } from "../../../../features/quotes-funnel/application/promotion-code-use-cases";
import {
  useSelectHealthProduct,
  useShoppingCart,
} from "../../../../features/quotes-funnel/application/shopping-cart-use-cases";
import { Product } from "../../../../features/quotes-funnel/domain/product";
import { PromotionCode } from "../../../../features/quotes-funnel/domain/promotion-code";
import { PaymentIntervalMonths } from "../../../../features/quotes-funnel/settings/payments";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { ProductId } from "../../../../settings/products";
import { Events, track } from "../../../../utils/analytics";
import { formatCurrency } from "../../../../utils/currency";

interface HealthProductViewModel {
  id: ProductId;
  price: ReactNode;
  discountedPrice: ReactNode | undefined;
  spendingLimit: string;
  isSelected: boolean;
}

interface HealthProductsListViewModel {
  healthProducts: HealthProductViewModel[];
  features: string[];
  promotionCode: PromotionCode | null;
  selectHealthProduct: (productId: ProductId) => void;
}

export const useHealthProductSelector = (
  hasBecasDiscount?: boolean
): HealthProductsListViewModel => {
  const selectHealthProductUseCase = useSelectHealthProduct();
  const { t, i18n } = useTranslation();
  const result = useResult();
  const cart = useShoppingCart();
  const promotionCode = usePromotionCode();

  const handleSelectHealthProduct = (productId: ProductId): void => {
    track(Events.ITEM_SELECTED, {
      eventSender: "health-product-card",
      selection: productId,
    });
    selectHealthProductUseCase(productId);
  };

  const healthProducts = useMemo(() => {
    const getDiscountedPrice = (productId: ProductId) => {
      if (!promotionCode || !hasBecasDiscount) return undefined;

      return parse(
        t("health_product_selector.monthly", {
          productPrice: formatCurrency(
            cart.getMonthlyDiscountedPriceByHealthProduct(productId),
            i18n.language,
            result?.currency
          ),
        })
      );
    };

    const getHealthProductPrice = (productId: ProductId) => {
      const isYearly = cart.paymentIntervalMonths === PaymentIntervalMonths.yearly;
      const translationKey = `health_product_selector.${isYearly ? "yearly" : "monthly"}`;

      return parse(
        t(translationKey, {
          productPrice: formatCurrency(
            cart.getPriceByHealthProduct(productId),
            i18n.language,
            result?.currency
          ),
        })
      );
    };

    return cart
      .getHealthProducts()
      .map((healthProduct: Product) => ({
        id: healthProduct.id,
        price: getHealthProductPrice(healthProduct.id),
        discountedPrice: getDiscountedPrice(healthProduct.id),
        spendingLimit: t(`health_product_selector.${healthProduct.id}.spending_limit`),
        isSelected: cart.getSelectedHealthProduct()?.id === healthProduct.id,
      }))
      .sort((a, b): number => {
        const priceA = cart.getPriceByHealthProduct(a.id);
        const priceB = cart.getPriceByHealthProduct(b.id);

        return priceA < priceB ? -1 : 1;
      });
  }, [cart, promotionCode, hasBecasDiscount, t, i18n.language, result?.currency]);

  const features = [
    t("health_product_selector.features.item_1"),
    t("health_product_selector.features.item_2"),
    t("health_product_selector.features.item_3"),
  ];

  return {
    healthProducts,
    features,
    promotionCode,
    selectHealthProduct: handleSelectHealthProduct,
  };
};
