import cn from "classnames";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";

import Button from "../../atoms/button/Button";
import { useCountry } from "../../func-core/core/application/hooks";
import { getLocalePathFromCountry } from "../../func-core/core/utils/navigation-utils";
import { track } from "../../utils/analytics";
import { useDealEventProperties } from "../deal-event-properties/use-cases/deal-event-properties-use-cases";
import * as styles from "./Navigation.module.scss";

const logoSVG: string = require("../../images/logo/barkibu.svg").default;

interface NavigationProps {
  email?: string;
}

const Navigation = ({ email }: NavigationProps): JSX.Element => {
  const { t } = useTranslation();
  const [userMenuIsExpanded, setUserMenuIsExpanded] = useState(false);
  const dealEventProperties = useDealEventProperties();
  const { country } = useCountry();

  const toggleUserMenu = () => {
    setUserMenuIsExpanded(!userMenuIsExpanded);

    trackUserMenuToggle();
  };

  const trackUserMenuToggle = () => {
    const properties = {
      ...dealEventProperties,
      block: "user-menu-read-more",
    };
    const eventName = userMenuIsExpanded ? "Block Collapsed" : "Block Collapsed";

    track(eventName, properties);
  };

  return (
    <header
      id="header"
      className={cn(
        styles.navigation,
        styles.navigationFunnel,
        "or-navigation or-navigation--funnel"
      )}
    >
      <Link to={getLocalePathFromCountry(country)} className={styles.brand}>
        <img src={logoSVG} alt="Barkibu" width="90px" height="21px" />
      </Link>

      {email && (
        <nav
          className={cn(styles.mainNavigation, "main-navigation")}
          aria-label={t("navigation.user_menu")}
        >
          <ul className={styles.menuUser}>
            <li>
              <div className={styles.expandable}>
                <Button
                  onClick={toggleUserMenu}
                  type="button"
                  aria-controls="user-menu-read-more"
                  aria-expanded={userMenuIsExpanded}
                  className={cn(styles.readMore, "read-more")}
                >
                  <span className={styles.main}>{email}</span>
                  <svg className={cn(styles.icon, "icon icon--arrowdown")} aria-hidden="true">
                    <use href="#icon-arrowdown"></use>
                  </svg>
                </Button>
                <div
                  className={styles.expandableContent}
                  hidden={!userMenuIsExpanded}
                  id="user-menu-read-more"
                >
                  <dl>
                    <dt>{t("navigation.connected_as")}</dt>
                    <dd>{email}</dd>
                  </dl>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Navigation;
