import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMemo } from "react";

import {
  createGlBenefitsViewModel,
  createHealthBenefitsViewModel,
  createPreventionBenefitsViewModel,
} from "../../domain/view-models";

export interface UseProductBenefits {
  benefits: string[];
}

type CreateViewModelFn<T extends unknown[]> = (
  translations: Record<string, string>,
  ...args: T
) => UseProductBenefits;

function useProductBenefits<T extends unknown[]>(
  countryIso: string,
  createViewModel: CreateViewModelFn<T>,
  ...additionalArgs: T
): UseProductBenefits {
  const { i18n } = useTranslation();

  const benefitsData = useMemo(() => {
    const translations = i18n.getResourceBundle(countryIso.toLowerCase(), "translation") as
      | Record<string, string>
      | undefined;

    if (!translations || Object.keys(translations).length === 0) {
      return { benefits: [] };
    }

    return createViewModel(translations, ...additionalArgs);
  }, [i18n, countryIso, createViewModel, additionalArgs]);

  return {
    benefits: benefitsData.benefits,
  };
}

export const useHealthProductBenefits = (countryIso: string): UseProductBenefits => {
  return useProductBenefits(countryIso, createHealthBenefitsViewModel);
};

export const usePreventionProductBenefits = (
  countryIso: string,
  species: string
): UseProductBenefits => {
  return useProductBenefits(countryIso, createPreventionBenefitsViewModel, species);
};

export const useGlProductBenefits = (countryIso: string): UseProductBenefits => {
  return useProductBenefits(countryIso, createGlBenefitsViewModel);
};
